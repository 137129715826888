@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@media screen and (max-width: 480px) {
    html,
    body {
        min-width: 320px;
    }
}

html {
    box-sizing: border-box;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font: inherit;
    background-color: #282c34;
    vertical-align: baseline;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Segoe UI", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
}

a {
    transition: color 0.2s ease, border-color 0.2s ease;
    color: inherit;
    text-decoration: none;
}

a:before {
    transition: color 0.2s ease, text-shadow 0.2s ease;
}

a:hover {
    color: #ff7496;
}

.icon {
    text-decoration: none;
    position: relative;
    border-bottom: none;
}

.icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}

.icon>.label {
    display: none;
}
.App {
    text-align: center;
}

.Input {
    background-color: #282c34;
    color: white;
}

.App-logo {
    height: 125px;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 1vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.App-main {
    background-color: #282c34;
    min-height: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
}

.App-link {
    color: #61dafb;
}

input {
    display: inline-block;
    background: #232529;
    color: whitesmoke;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin-bottom: 5px;
    width: 100%;
}

button {
    display: inline-block;
    background: #232529;
    color: whitesmoke;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
}

.loginBtn {
    display: inline-block;
    background: #232529;
    color: whitesmoke;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 10px;
}

.loginBtn:hover {
    cursor: pointer;
}

span.label {
    font-family: serif;
    font-weight: normal;
}

.loginBtn span.fab {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    margin: 2px;
    font-size: 38px;
}

span.s3cicon {
    background: url('/badgeblack_sm.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}
