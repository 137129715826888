.App {
    text-align: center;
}

.Input {
    background-color: #282c34;
    color: white;
}

.App-logo {
    height: 125px;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.App-main {
    background-color: #282c34;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.App-link {
    color: #61dafb;
}

input {
    display: inline-block;
    background: #232529;
    color: whitesmoke;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin-bottom: 5px;
    width: 100%;
}

button {
    display: inline-block;
    background: #232529;
    color: whitesmoke;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
}

.loginBtn {
    display: inline-block;
    background: #232529;
    color: whitesmoke;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 10px;
}

.loginBtn:hover {
    cursor: pointer;
}

span.label {
    font-family: serif;
    font-weight: normal;
}

.loginBtn span.fab {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    margin: 2px;
    font-size: 38px;
}

span.s3cicon {
    background: url('/badgeblack_sm.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}