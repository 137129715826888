@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@media screen and (max-width: 480px) {
    html,
    body {
        min-width: 320px;
    }
}

html {
    box-sizing: border-box;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font: inherit;
    background-color: #282c34;
    vertical-align: baseline;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Segoe UI", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
}

a {
    -moz-transition: color 0.2s ease, border-color 0.2s ease;
    -webkit-transition: color 0.2s ease, border-color 0.2s ease;
    -ms-transition: color 0.2s ease, border-color 0.2s ease;
    transition: color 0.2s ease, border-color 0.2s ease;
    color: inherit;
    text-decoration: none;
}

a:before {
    -moz-transition: color 0.2s ease, text-shadow 0.2s ease;
    -webkit-transition: color 0.2s ease, text-shadow 0.2s ease;
    -ms-transition: color 0.2s ease, text-shadow 0.2s ease;
    transition: color 0.2s ease, text-shadow 0.2s ease;
}

a:hover {
    color: #ff7496;
}

.icon {
    text-decoration: none;
    position: relative;
    border-bottom: none;
}

.icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}

.icon>.label {
    display: none;
}